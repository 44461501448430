const AUTOCOMPLETES = {
  achternaam: 'family-name',
  tussenvoegsel: 'off',
  voornaam: 'given-name',
  geslacht: 'off',
  'geboortedatum-day': 'bday-day',
  'geboortedatum-month': 'bday-month',
  'geboortedatum-year': 'bday-year',
  telefoon: 'tel',
  email: 'email',
  straatnaam: 'address-line1',
  huisnummer: 'off',
  huisnrtoevoeging: 'off',
  postcode: 'postal-code',
  plaats: 'address-level2',
  land: 'country-name',
  iban: 'off',
};

export default AUTOCOMPLETES;
